import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./ShopFor.module.scss";
import HK from 'factory/modules/HK';
import OwlCarousel from 'utility/templates/owlCarousel';
import TabsLine from 'components/Assets/ToolBox/TabsLine';
import TabsBox from 'components/Assets/ToolBox/TabsBox';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';
import GritzoProductCard from 'components/VariantCards/GritzoProductCard';

class ShopFor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter1: "CL-5855",
            filter2: "height",
            variantData: null,
            showSkel: false,
            TABS_DATA: [
                { nm: "Height", dis_nm: "Height", img: "/newGritzo/scale.svg", type: 2, value: "height" },
                { nm: "Weight", dis_nm: "Weight", img: "/newGritzo/meter.svg", type: 2, value: "weight" },
                { nm: "Overall Growth", dis_nm: "Overall Growth", img: "/newGritzo/mind_new.svg", type: 2, value: "overallgrowth" },
            ],
            TABS_DATA_2: [
                { nm: "Boy", img: "/newGritzo/boy.svg", type: 1, value: "CL-5855" },
                { nm: "Girl", img: "/newGritzo/girl.svg", type: 1, value: "CL-5856" }
            ]
        };
    }

    componentDidMount() {
        const { data } = this.props;
        let newArr = [];
        if (data && data.scItms && data.scItms.length > 0) {
            data.scItms.map(ele => {
                let newObj = {};
                newObj.nm = ele.dis_nm;
                newObj.type = 1;
                let value = (ele.imgItm && ele.imgItm.lp) || "";
                if (ele.dis_nm === "Boy") {
                    newObj.img = "/newGritzo/boy.svg";
                    newObj.value = value + "?fl=gz-gender:boy,boygirl";
                } else {
                    newObj.img = "/newGritzo/girl.svg";
                    newObj.value = value + "?fl=gz-gender:girl,boygirl";
                }
                newArr.push(newObj);
            });
            this.setState({ TABS_DATA_2: newArr, filter1: newArr[0] && newArr[0].value }, () => {
                this.getCatalogData();
            });
        }
    }

    createAPIURL = (/*selectedGoal */) => {
        const { filter1, filter2 } = this.state;
        let _url = '';
        // let splitName = selectedGoal && selectedGoal.nm.split(' ')
        // let finalName = '';
        // splitName.map(eachName => {
        //     if (finalName && finalName.length > 1) {
        //         finalName = finalName + '_' + eachName.toLowerCase()
        //     } else {
        //         finalName = eachName.toLowerCase()
        //     }
        // })
        // _url = `?goalNm=${filter2}&fl=fn-att-gender:${filter1}&pageNo=1&perPage=24&excludeOOS=true`;
        _url = `${filter1};gz-goal:${filter2}&pageNo=1&perPage=24&excludeOOS=true`;
        return _url;
    };

    getCatalogData = () => {
        this.setState({ showSkel: true });
        HK.ajaxCall({
            method: "get",
            url: HK.config.APIS.category.categoryFilter + this.createAPIURL()
        }, true).then(res => {
            if (res && res.data.results) {
                this.setState({ variantData: res.data.results.variants });
            } else {
                HK.errorPopup("Something went wrong, please try again!");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
            this.setState({ showSkel: false });
        }).catch(error => {
            this.setState({ showSkel: false });
            HK.errorPopup("Something went wrong, please try again!");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: error
            });
        });
    };

    updateFilter = (type, value, data) => {
        let _type = type;
        let _value = value;
        if (data) {
            _type = data.type;
            _value = data.value;
        }
        if (_type === 1) {
            this.setState({ filter1: _value }, () => {
                this.getCatalogData();
            });
        } else if (_type === 2) {
            this.setState({ filter2: _value }, () => {
                this.getCatalogData();
            });
        } else {
            console.log("unknown filter update");
        }
    };

    render() {
        const { variantData, showSkel, TABS_DATA_2, TABS_DATA } = this.state;
        let url = '';
        let placeholderConfig = {
            isConcern: false,
            isDiscount: false
        };
        variantData && variantData.length > 0 && variantData.forEach((item) => {
            if(item){
                if (item && item.concerns && item.concerns.length > 0) {
                    placeholderConfig.isConcern = true;
                }
                const _mrp = item &&  (item.mrp || (item.sv_bsc && item.sv_bsc.mrp));
                const _discount = item && (item.discount || (item.sv_bsc && item.sv_bsc.discount));
                if (_mrp && (_mrp !== item.offer_pr) && (_mrp !== item.offerPrice) && _discount > 0) {
                    placeholderConfig.isDiscount = true;
                }
            }
            
        });
        return (
            <div className={convertToModule(styles, "shopfor-component")}>
                <div className={convertToModule(styles, "shopfor-inner HK-pagecontainer")}>
                    <div className={convertToModule(styles, "sf-heading")}>
                        Shop For
                    </div>
                    <TabsBox tabsData={TABS_DATA_2} clickHandler={this.updateFilter} />
                    <TabsLine tabsData={TABS_DATA} clickHandler={this.updateFilter} />
                    {
                        showSkel ?
                            (
                                this.props.isMobile ? <SkeletonPattern pattern={[8]} /> :
                                    <SkeletonPattern pattern={[13]} />
                            )
                            :
                            <div className={convertToModule(styles, HK.isMobile() ? "BS-products BS-mob" : "BS-products")}>
                                {
                                    <OwlCarousel
                                        className={"BS-Desk goal-section-container"}
                                        margin={0}
                                        items={HK.isMobile() ? 2 : 3}
                                        slideBy={HK.isMobile() ? 1 : 2}
                                        dots={false}
                                        nav={true}
                                        responsive={false} //todoS same breakpoints
                                    >
                                        {
                                            variantData && variantData.map((item, index) => {
                                                if (item.urlFragment) {
                                                    url = item.pk_type ? '/pk' + item.urlFragment : '/sv' + item.urlFragment;
                                                    url += `?navKey=${item.navKey}`;
                                                }
                                                return (
                                                    <GritzoProductCard placeholderConfig={placeholderConfig} pageType={this.props.pageType} key={index} data={item} url={url} index={index} />
                                                );
                                            })
                                        }
                                    </OwlCarousel>
                                }
                            </div>
                    }

                </div>
            </div>
        );
    }
}

export default ShopFor;