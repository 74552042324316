import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./TabsWithVariants.module.scss";
import HK from 'factory/modules/HK';
import OwlCarousel from 'utility/templates/owlCarousel';
import TabsLine from 'components/Assets/ToolBox/TabsLine';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';
import GritzoProductCard from 'components/VariantCards/GritzoProductCard';

// const TABS_DATA = [
//     { nm: "Deals", img: "/newGritzo/percentage.png", type: 2, value: "weight_loss" },
//     { nm: "New Launch", img: "/newGritzo/rocket.png", type: 2, value: "weight_loss" },
//     { nm: "Best Seller", img: "/newGritzo/badge.png", type: 2, value: "weight_loss" },
// ]
class TabsWithVariants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSection: null,
            showSkel: false,
            placeholderConfig: {
                isConcern: false,
                isDiscount: false
            },
        };
    }

    componentDidMount() {
        const { data } = this.props;
        const sections = data && data.scContent;
        if (sections && sections.length > 0) {
            this.setState({ selectedSection: sections[0] });
            this.createPlaceHolder(sections[0]);
        }
    }

    loadData = (x, y, data) => {
        this.setState({ showSkel: true }, () => {
            this.setState({ selectedSection: data }, () => {
                setTimeout(() => {
                    this.setState({ showSkel: false });
                }, 500);
            });
        });
        this.createPlaceHolder(data);
    };

    createPlaceHolder = (data) => {
        let placeholder = {
            isConcern: false,
            isDiscount: false
        };
        data && data.attributeArea && data.attributeArea.length > 0 && data.attributeArea.forEach((itm) => {
            const item = itm && itm.productVariantInfo || itm.packInfo;
            if(item){
                if (item && item.concerns && item.concerns.length > 0) {
                    placeholder.isConcern = true;
                }
                const _mrp = item && (item.mrp || (item.sv_bsc && item.sv_bsc.mrp));
                const _discount = item && (item.discount || item.sv_bsc && item.sv_bsc && item.sv_bsc.discount);
                if (_mrp && (_mrp !== item.offer_pr) && (_mrp !== item.offerPrice) && _discount > 0) {
                    placeholder.isDiscount = true;
                }
            }
            
        });
        this.setState({ placeholderConfig: placeholder });
    };

    render() {
        const { data } = this.props;
        const sections = data && data.scContent;
        const { selectedSection, showSkel } = this.state;
        return (
            sections &&
            <div className={convertToModule(styles, "tabswithvariant-component")}>
                <div className={convertToModule(styles, "tabswithvariant-inner HK-pagecontainer")}>
                    <TabsLine tabsData={sections} clickHandler={this.loadData} parentClass='tabswithvariant' />
                    {
                        selectedSection &&
                        selectedSection.attributeArea &&
                        selectedSection.attributeArea.length > 0 &&
                        <div className={convertToModule(styles, "BS-products")}>
                            {
                                showSkel ?
                                    (
                                        this.props.isMobile ? <SkeletonPattern pattern={[8]} /> :
                                            <SkeletonPattern pattern={[13]} />
                                    )
                                    :
                                    <OwlCarousel
                                        className={"BS-Desk"}
                                        margin={0}
                                        // items={HK.config.slider.getSlidesNo(selectedSection.scItms, 2, 3)}
                                        items={HK.isMobile() ? 2 : 3}
                                        slideBy={HK.isMobile() ? 1 : 2}
                                        dots={false}
                                        nav={HK.isMobile() ? false : true}
                                        responsive={false} //todoS same breakpoints
                                    >
                                        {
                                            selectedSection.attributeArea.map((item, index) => {
                                                let url = "";
                                                let isPack = item.type === 'pack';
                                                let _item = isPack ? item.packInfo : item.productVariantInfo;
                                                if (isPack && _item) {
                                                    url = '/pk' + _item.urlFragment;
                                                    url += `?navKey=${_item.navKey}`;
                                                } else if (_item && _item.urlIdentifiers && _item.urlIdentifiers.urlFragment) {
                                                    url = item.pk_type ? '/pk' + _item.urlIdentifiers.urlFragment : '/sv' + _item.urlIdentifiers.urlFragment;
                                                    url += `?navKey=${_item.urlIdentifiers.navKey}`;
                                                }
                                                return _item && (
                                                    <GritzoProductCard placeholderConfig={this.state.placeholderConfig} pageType={this.props.pageType} isPack={isPack} key={index} data={_item} url={url} title={this.props.title} eventName={selectedSection.dis_nm} widgetPosition={this.props.data.positionIdx} index={index} />
                                                );
                                            })
                                        }
                                    </OwlCarousel>
                            }

                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default TabsWithVariants;